@import 'variable';
@import 'mixin';
/* Footer
-------------------------------------------------- */
footer {
  font-size: $font-size-small;
  text-align: center;
  ul {
    font-variant: small-caps;

    li {
      display: inline-block;

      &:first-child:before {
        content: "";
      }
      
      &:before {
        margin: 0 5px;
        content: "|";
        font-size: $font-size-extra-small;
      }

      a {
        color: $oe-black;
      }
    }
  }
}
  
.copyright {
  padding: 20px 0;
  border-top: 1px solid $oe-dark-gray;
}